import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';
import Variable, { ELEMENT_TYPES, SimpleTypeLabels, ValueType, VariableKey, VariableType } from '@core/models/Variable';

import { Alert, Button, Dropdown, Icon, MenuItem, Switch, Tag } from '@components/dmp';
import { ButtonIcon } from '@components/dmp/Button';

import EmptyState from '@components/EmptyState';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import VariableEditor from '@components/editor/VariableEditor';
import Fire from '@root/Fire';
import { compareConnectedVariableDefinitions } from '@root/utils/VariableIndex';

// Define types that can be explicitly added to the template
const EXPLICIT_ADD = [VariableType.SIMPLE, VariableType.CALCULATED, VariableType.PARTY, VariableType.PROTECTED];

@autoBindMethods
export default class ElementsSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    onColConfig: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      type: VariableType.SIMPLE,
      editingVariable: null,
      creating: false,
      hasConnectedVariables: !!_.find(props.deal.variables, { isConnected: true }),
      loadedFVConnectedDefinitions: false,
    };

    this.refContainer = createRef();
    this.refNewElement = createRef();
    this.refVars = {};
    this.establishRefs(props.deal.variables);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { variableIndex } = this.props;
    const { hasConnectedVariables, loadedFVConnectedDefinitions, type } = this.state;
    if (!_.isEqual(prevProps.deal, this.props.deal)) {
      this.establishRefs(this.props.deal.variables);
    }

    if (
      type === VariableType.CONNECTED &&
      hasConnectedVariables &&
      variableIndex.index.l[VariableType.CONNECTED] &&
      !loadedFVConnectedDefinitions
    ) {
      this.setState({ loadedFVConnectedDefinitions: true });
    }
  }

  establishRefs(vars) {
    _.forEach(vars, (v) => (this.refVars[v.name] = createRef()));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // https://reactjs.org/docs/error-boundaries.html
  // VariableEditor can throw a DOM error if a variable rename occurred because it changes the target container div
  // React complains about the parent change, but the div DOES still get removed,
  // so catching it here and resetting error state effectively handles the error and prevents white screen of death
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: false });
  }

  get selectedType() {
    return _.find(ELEMENT_TYPES, { type: this.state.type });
  }

  async toggleAI(enable) {
    const { type } = this.state;
    const { deal } = this.props;
    const elements = _.filter(this.getElements(type), { canExtract: true });

    await Fire.bulkUpdateVariableExtract(deal.dealID, elements, enable);
  }

  deleteElement(el) {
    Fire.deleteVariable(el.deal, el);
  }

  render() {
    const { type, editingVariable, creating } = this.state;
    const { deal, onColConfig, variableIndex } = this.props;

    if (!deal) return null;

    const elements = this.getElements(type);
    const canAdd = EXPLICIT_ADD.indexOf(type) > -1;
    const unused = _.filter(elements, 'isUnused').length;
    const typeDef = _.find(ELEMENT_TYPES, { type });
    const elementTabs = _.filter(ELEMENT_TYPES, ({ type }) => {
      return type !== VariableType.FOOTNOTE && type !== VariableType.PAGE_COUNT;
    });

    const variableExtractionEnabled = _.every(_.filter(elements, { canExtract: true }), { extract: true });
    const needsDisplayName = _.find(elements, (el) => {
      return !el.displayName && el.extract;
    });

    return (
      <div className="elements-sidebar">
        <DealPanelItem borderBottom className="add-element">
          <Dropdown
            id="dd-element-types"
            title={`${typeDef.plural} (${elements.length})`}
            size="small"
            onSelect={(entity) => this.setState({ type: entity.type, creating: false })}
            dmpStyle="link"
            menuWidth={200}
            noPadding
            data-cy="element-types"
          >
            {elementTabs.map((entity, idx) => (
              <MenuItem key={idx} eventKey={entity} icon={entity.icon}>
                {entity.plural}
              </MenuItem>
            ))}
          </Dropdown>

          <div className="spacer" />
          <TooltipButton tip={typeDef.tip} placement="top">
            <ButtonIcon size="default" icon="info" className="element-tip" data-cy="element-tip" />
          </TooltipButton>
          {canAdd && (
            <Button
              ref={this.refNewElement}
              className="add-entity"
              size="small"
              icon={typeDef.icon}
              onClick={() => this.setState({ creating: true })}
              data-cy="new-variable"
            >
              New
            </Button>
          )}
        </DealPanelItem>

        {type === VariableType.SIMPLE && !!deal.template?.documentAI && (
          <DealPanelItem borderBottom className="enable-ai">
            {needsDisplayName && (
              <Alert dmpStyle="danger" className="alert" size="xsmall">
                <b>Display name</b> is required in order for AI-extraction to work properly. If you do not set this, the
                variable will not be extracted.
              </Alert>
            )}
            <div className="toggle-ai">
              <span className="extract-icon">
                <Icon name="aiAuto" />
                Use AI-extracted values for all variables
              </span>
              <Switch
                id="chk-extracr"
                checked={variableExtractionEnabled}
                onChange={() => this.toggleAI(!variableExtractionEnabled)}
                className="extract-switch"
                size="small"
              />
            </div>
          </DealPanelItem>
        )}

        {elements.length === 0 && this.renderEmpty(type)}

        <div
          className="elements-list panel-scroll"
          ref={this.refContainer}
          data-cy="elements-list"
          style={editingVariable && { overflowY: 'hidden' }}
        >
          {_.map(elements, this.renderElement)}
        </div>

        {editingVariable && (
          <VariableEditor
            rootClose
            showPropertySelector={false}
            target={this.refVars[editingVariable.name].current}
            deal={deal}
            text={`[${editingVariable.type}${editingVariable.name}]`}
            hide={() => this.setState({ editingVariable: null })}
            onColConfig={onColConfig}
            variableIndex={variableIndex}
            placement="left"
          />
        )}
        {creating && (
          <VariableEditor
            creating
            showPropertySelector={false}
            target={this.refNewElement.current}
            deal={deal}
            text={`[${type}`}
            hide={() => this.setState({ editingVariable: null, creating: false })}
            variableIndex={variableIndex}
          />
        )}
      </div>
    );
  }

  renderEmpty(type) {
    const { deal } = this.props;
    const t = _.find(ELEMENT_TYPES, { type });
    return <EmptyState title={`There are no ${t.plural} yet in this ${deal.documentType}`} />;
  }

  getElements(type) {
    const deal = this.props.deal;
    let vars = [],
      partyIDs = [];

    if (type === VariableType.PARTY) {
      //build unique list of all referenced parties
      _.forEach(deal.variables, (v, k) => {
        if (v.type != VariableType.PARTY) return;
        const id = k.split('.')[0];
        if (partyIDs.indexOf(id) < 0) {
          partyIDs.push(id);
          vars.push(new Variable({ name: id, type: '@', deal }));
        }
      });
    } else {
      //only allow definition for vars themselves, not derived values (.spelled etc)
      vars = _.filter(deal.variables, (v) => v.type === type && v.name.split('.').length === 1);
    }

    // Sort alphabetically
    vars = _.sortBy(vars, 'name');

    return vars;
  }

  renderElement(el, idx) {
    const { variableIndex } = this.props;
    const { loadedFVConnectedDefinitions } = this.state;
    const isSimple = el.type === VariableType.SIMPLE;
    const isTable = el.valueType === ValueType.TABLE;
    const isImage = el.valueType === ValueType.IMAGE;
    const isRef = el.type === VariableType.REF;
    const isSecret = el.type === VariableType.PROTECTED;
    const isConnected = el.type === VariableType.CONNECTED;
    const displayValueType = _.find(SimpleTypeLabels, { data: el.valueType || ValueType.STRING });

    // location of references
    const loc = isRef ? el.locations : [];
    const displayLoc = loc.length > 0 ? loc[0].displayReference : 'deleted section';
    const assigned = el.assigned ? el.deal.getPartyByID(el.assigned) : null;
    const disabled = isConnected && !loadedFVConnectedDefinitions;
    let broken = el.isBroken;
    let resolve;

    if (isConnected && loadedFVConnectedDefinitions) {
      const comparison = compareConnectedVariableDefinitions(variableIndex, el);
      broken = comparison.broken;
      resolve = comparison.resolve;
    }

    const needsDisplayName = !el.displayName && el.extract;

    return (
      <DealPanelItem borderBottom key={idx} data-cy="element-item" className={cx({ error: needsDisplayName })}>
        <div className="element-topline" data-cy="variable-row">
          <div className="wrap-element-text">
            <TooltipButton tip={el.displayName} disabled={!el.displayName || isRef} placement="top">
              <div
                className={cx('template-variable', VariableKey[el.type], { broken }, { disabled })}
                data-cy={`template-${VariableKey[el.type].toLowerCase()}-variable`}
                ref={this.refVars[el.name]}
                onClick={() => this.setState({ editingVariable: el })}
              >
                {resolve && <div className="resolve"></div>}
                {isRef ? (el.isBroken ? 'Broken' : el.val) : `${el.type}${el.name}`}
              </div>
            </TooltipButton>

            {el.extract && (
              <TooltipButton tip="Value to be extracted by AI" placement="top">
                <Icon name="aiAuto" className="ai-icon" />
              </TooltipButton>
            )}
            {isSimple && <div className="element-type default">{displayValueType.label}</div>}
          </div>
          <div className="spacer" />

          {assigned && (
            <TooltipButton tip={`Assigned to ${assigned.displayName}`} placement="top">
              <ButtonIcon
                icon="users"
                size="default"
                onClick={() => this.setState({ editingVariable: el })}
                data-cy="btn-assigned-party"
              />
            </TooltipButton>
          )}

          {el.isUnused && (
            <TooltipButton tip="Element not used in template (Click to delete)" placement="top">
              <ButtonIcon
                icon="trash"
                size="default"
                className="delete"
                onClick={() => this.deleteElement(el)}
                data-cy="btn-delete-var"
              />
            </TooltipButton>
          )}

          {el.isBroken && (
            <TooltipButton tip="This variable has an error (Click to fix)" placement="top">
              <ButtonIcon
                icon="exclamation"
                size="default"
                className="broken"
                onClick={() => this.setState({ editingVariable: el })}
              />
            </TooltipButton>
          )}
        </div>
        <div className="element-details">
          {el.valueType === ValueType.LIST && (
            <div className="list-values">{_.map(el.options, 'title').join(', ')}</div>
          )}
          {el.isRedacted && (
            <div className="default-value" data-cy="default-value">
              <Tag color="gray" size="xsmall">
                <Icon name="excluded" size="small" /> Value will be redacted
              </Tag>
            </div>
          )}
          {!el.isRedacted && el.val && !isRef && !isSecret && !isTable && !isImage && (
            <div className="default-value" data-cy="default-value">
              ({el.isBroken ? 'Error' : 'Default'}: {el.val})
            </div>
          )}
          {isRef && <div className="default-value">(Referenced in {displayLoc})</div>}
        </div>
      </DealPanelItem>
    );
  }
}
