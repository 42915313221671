import React, { Component, createRef } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay } from 'react-bootstrap';

import { NOTIFICATION_TYPES } from '@core/models/Notification';
import Report, { DEFAULT_REPORTS } from '@core/models/Report';
import User from '@core/models/User';
import { USER_STATES } from '@core/models/UserStateStore';

import { Alert, Button, Dropdown, Ellipsis, Loader, MenuItem, Popover } from '@components/dmp';

import Onboarding from '@components/Onboarding';
import UpgradePrompt from '@components/UpgradePrompt';
import NotificationForm from '@components/page-reports/NotificationForm';
import ReportsGrid from '@components/page-reports/ReportsGrid';
import ConfigureReport from '@components/reports/ConfigureReport';
import API from '@root/ApiClient';
import CRM, { ATTRIBUTES } from '@root/CRM';
import CONFIG from '@root/Config';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

const USER_DASHBOARD_LABEL = 'My Dashboard';

@autobindMethods
class WelcomePage extends Component {
  static defaultProps = {
    subscription: null,
    team: null,
    teams: [],
  };

  static propTypes = {
    checkSubscription: PropTypes.func.isRequired,
    getTeams: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    og: PropTypes.func.isRequired,
    selectTeam: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    toggleTeamCreation: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      stats: null,
      isTeamReports: false,
      newReport: null,
      reportSendStatus: null, // [null, 'sending', 'sent', 'error']
    };

    this.notificationButtonRef = createRef();
    this.notificationRef = createRef();
  }

  componentDidMount() {
    this.props.og({ title: `Outlaw - Dashboard` });
    this.verifyUserReports();
    this.getDealStats();
  }

  // The user can edit if it's in User state or Team state and can edit the team
  get canEdit() {
    const { isTeamReports } = this.state;
    const { user, team } = this.props;
    return !isTeamReports ? true : user.canEditTeam(team.teamID);
  }

  get availableReports() {
    const { team, user } = this.props;
    const { isTeamReports } = this.state;
    let filters = user.filters;

    if (isTeamReports && team) {
      filters = team.filters;
    }

    return _.map(filters, ({ filterID, teamID, title, userID }) => {
      return new Report({ filterID, teamID, userID, title });
    });
  }

  async verifyUserReports() {
    const { user } = this.props;
    const { id, states, reports } = user;

    if (!states.hasCompleted(USER_STATES.DASHBOARD_REPORTS) && !reports.length) {
      this.setState({ isCreatingReports: true });
      await Fire.saveReport(new Report({ ...DEFAULT_REPORTS.RECENT, userID: id }));
      await Fire.saveReport(new Report({ ...DEFAULT_REPORTS.CONTRACTS_PIPELINE, userID: id }));
      await user.states.complete(USER_STATES.DASHBOARD_REPORTS);
      this.setState({ isCreatingReports: false });
    }
  }

  /*
    Since we merged Dashboard Reports, this is not rendered visually to the user anymore
    meaning that we could move this somwhere else, potentially app.js
  */
  async getDealStats() {
    const { user } = this.props;

    this.setState({ isLoading: true });

    const stats = await API.call('getDealStats', { uid: user.id });
    this.setState({ stats, isLoading: false });

    const { dmp } = stats;
    const atts = {
      [ATTRIBUTES.DEALS_TOTAL]: dmp.total,
      [ATTRIBUTES.DEALS_RECIPIENT]: dmp.recipient,
      [ATTRIBUTES.DEALS_OWNER]: dmp.owner,
      [ATTRIBUTES.DEALS_CLOSED]: dmp.closed,
    };

    CRM.updateUser(atts);
  }

  /*
    This piece of code exist because compared to the /user/ data, the /teams/ don't auto-reload on changes.
    There might be a better way to handle this, but it's out of scope for this PR (and feature).
    https://trello.com/c/CCaItq5q/218-teams-refreshing-behaviors
  */
  async reloadTeamReports() {
    await this.props.getTeams(this.props.user);
  }

  async handleCreateReport(newReport) {
    if (!newReport) return;
    await Fire.saveReport(newReport);

    if (newReport.isTeamReport) {
      await this.reloadTeamReports();
    }
  }

  handleAddReport() {
    const { team, user } = this.props;
    const { isTeamReports } = this.state;
    const newReportData = isTeamReports ? { teamID: team.teamID } : { userID: user.id };
    this.setState({ configuredNewReport: new Report(newReportData) });
  }

  onSelectDashboard(dashboardKey) {
    const { selectTeam, teams } = this.props;

    if (dashboardKey === 'user') {
      this.setState({ isTeamReports: false });
      return;
    }

    const team = _.find(teams, { teamID: dashboardKey });
    if (team) {
      selectTeam(team);
      this.setState({ isTeamReports: true });
    }
  }

  renderTitle() {
    const { team } = this.props;
    const { isTeamReports } = this.state;

    let title = 'Dashboard';

    return (
      <>
        <div className="title-bar">
          <h1 className="d-flex">
            {title}
            {isTeamReports && <Ellipsis className="team-name">{team.info.name}</Ellipsis>}
          </h1>

          <div className="actions">
            {this.renderDashboardNotification()}
            {this.renderDashboardDropdown()}
            {this.renderAddReportButton()}
          </div>
        </div>

        {isTeamReports && this.canEdit && (
          <Alert>
            <span>Important!</span> As an owner of the <strong>{team.info.name}</strong> team, you are managing team
            reports that will visible to all of its members.
          </Alert>
        )}
      </>
    );
  }

  /*
    Since Popovers unmount and re-mount,
    we must handle the API behaviours outside of it and pass it as props.
  */
  async handleSendReport() {
    const { user } = this.props;

    await this.setState({ reportSendStatus: 'sending' });

    try {
      await API.call('sendDashboardReport', { uid: user.id }, async (response) => {
        if (response.success) {
          await this.setState({ reportSendStatus: 'sent' });
        } else {
          await this.setState({ reportSendStatus: 'error' });
        }
      });
    } catch (err) {
      await this.setState({ reportSendStatus: 'error' });
    }
  }

  renderDashboardNotification() {
    const { user } = this.props;
    const { notificationStore } = user;
    const { showNotification, reportSendStatus } = this.state;
    const isActive = notificationStore.isActive(NOTIFICATION_TYPES.DASHBOARD);
    const notification = isActive ? notificationStore.notifications[NOTIFICATION_TYPES.DASHBOARD] : null;

    let label = '';
    if (isActive) {
      label = `Sending ${notification.frequency}`;
    }

    if (Dealer.mobile) return null;

    // If a User does not have filters, no notifications can be enabled / configured
    if (!user.filters.length) return null;

    const NotificationPopoverFixed = ({ style, ...rest }) => {
      return (
        <Popover
          {...rest}
          id="popover-dashboard-notifications"
          title="Reports via email"
          width={290}
          style={{ ...style, left: '50%', marginLeft: -140 }}
          data-cy="popover-dashboard-notifications"
        >
          <NotificationForm
            user={user}
            notification={notification}
            onHide={() => this.setState({ showNotification: false })}
            sendStatus={reportSendStatus}
            handleSend={this.handleSendReport}
          />
        </Popover>
      );
    };

    return (
      <div ref={this.notificationRef} style={{ position: 'relative' }}>
        <Button
          onClick={() => this.setState({ showNotification: true })}
          ref={this.notificationButtonRef}
          icon="email"
          iconOnly={!isActive}
          data-cy="btn-email"
        >
          {label}
        </Button>
        <Overlay
          placement="bottom"
          rootClose
          show={showNotification}
          container={this.notificationRef.current}
          target={this.notificationButtonRef.current}
          onHide={() => this.setState({ showNotification: false, reportSendStatus: null })}
          shouldUpdatePosition
        >
          <NotificationPopoverFixed />
        </Overlay>
      </div>
    );
  }

  renderAddReportButton() {
    const { team } = this.props;
    const { isTeamReports } = this.state;

    if (!this.canEdit || Dealer.mobile) return null;

    // If a team does not have filters, no report can be created
    if (isTeamReports && !team.filters.length) return null;

    return (
      <Button className="btn-add-report" dmpStyle="primary" onClick={this.handleAddReport} data-cy="btn-add-report">
        New Report
      </Button>
    );
  }

  renderDashboardDropdown() {
    const { team, teams } = this.props;
    const { isTeamReports } = this.state;
    const title = isTeamReports && team ? team.info.name : USER_DASHBOARD_LABEL;

    return (
      <Dropdown
        id="dashboard-select"
        onSelect={this.onSelectDashboard}
        pullRight
        title={title}
        width={200}
        menuWidth={270}
      >
        <MenuItem eventKey="user" key="user" active={title === USER_DASHBOARD_LABEL}>
          {USER_DASHBOARD_LABEL}
        </MenuItem>
        <MenuItem divider />
        {!!teams.length &&
          _.map(teams, (teamItem) => (
            <MenuItem
              eventKey={teamItem.teamID}
              key={teamItem.teamID}
              active={title !== USER_DASHBOARD_LABEL && teamItem.teamID === team.teamID}
            >
              {teamItem.info.name}
            </MenuItem>
          ))}
        {!teams.length && (
          <MenuItem key="no-team" disabled>
            No team created
          </MenuItem>
        )}
      </Dropdown>
    );
  }

  render() {
    const { user, team, teams, getTeams } = this.props;
    const { isLoading, isCreatingReports, isTeamReports, configuredNewReport } = this.state;
    const reports = isTeamReports ? team.reports : user.reports;
    const displayedTeam = isTeamReports ? team : null;

    if (isLoading || isCreatingReports) {
      return (
        <div className="page-reports">
          <div className="reports">
            <Loader centered full size="large" />
          </div>
        </div>
      );
    }

    return (
      <div className="page-reports">
        <div className="reports">
          {this.renderTitle()}

          {this.renderOnboarding()}

          {this.renderPrompt()}

          <ReportsGrid
            availableReports={this.availableReports}
            canEdit={this.canEdit}
            getTeams={getTeams}
            team={displayedTeam}
            reports={reports}
            teams={teams}
            user={user}
          />
        </div>

        {configuredNewReport && (
          <ConfigureReport
            availableReports={this.availableReports}
            onClose={() => this.setState({ configuredNewReport: null })}
            onSave={this.handleCreateReport}
            report={configuredNewReport}
            show={true}
            teams={teams}
            user={user}
          />
        )}
      </div>
    );
  }

  renderOnboarding() {
    const { user, history, toggleTeamCreation } = this.props;
    const { isLoading, isTeamReports, stats } = this.state;

    if (isTeamReports || CONFIG.INSTANCE) return null;

    return (
      <Onboarding
        loading={isLoading}
        history={history}
        user={user}
        toggleTeamCreation={toggleTeamCreation}
        stats={stats}
      />
    );
  }

  renderPrompt() {
    const { subscription, history, location, user, checkSubscription } = this.props;
    const { isTeamReports } = this.state;

    if (isTeamReports) return null;

    return (
      <UpgradePrompt
        checkSubscription={checkSubscription}
        history={history}
        location={location}
        subscription={subscription}
        user={user}
      />
    );
  }
}

export default WelcomePage;
